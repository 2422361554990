import React, { useState, useEffect, ChangeEvent } from 'react';
import SideMenu from '../../layouts/Default/SideMenu';
import { Box, TextField, Button, Typography, Grid, IconButton } from '@mui/material';
import girlPayment from "../../assets/images/girl-payment.svg";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const PaymentDetailsForm = () => {
  const { t } = useTranslation();
  const [cardNumber, setCardNumber] = useState('');
  const [expiryMonth, setExpiryMonth] = useState('');
  const [expiryYear, setExpiryYear] = useState('');
  const [cvv, setCvv] = useState('');
  const [cardholderName, setCardholderName] = useState('');
  const [errors, setErrors] = useState({
    cardNumber: '',
    expiryMonth: '',
    expiryYear: '',
    cvv: '',
    cardholderName: '',
  });
  const [sessionId, setSessionId] = useState<string | null>(null);
  const navigate = useNavigate();

  useEffect(() => {
    fetch('http://localhost:5000/api/pagseguro-session')
      .then((response) => response.json())
      .then((data) => {
        if (data.sessionId) {
          setSessionId(data.sessionId);
          PagSeguroDirectPayment.setSessionId(data.sessionId);
        }
      })
      .catch(console.error);
  }, []);

  const validateField = (fieldName: string, value: string) => {
    let errorMessage = '';
    if (!value) {
      errorMessage = t('fieldIsRequired');
    } else {
      if (fieldName === 'cardNumber' && value.length < 16) {
        errorMessage = t('invalidCardNumber');
      } else if (fieldName === 'expiryMonth' && (parseInt(value) < 1 || parseInt(value) > 12)) {
        errorMessage = t('invalidExpiryMonth');
      } else if (fieldName === 'expiryYear' && value.length < 4) {
        errorMessage = t('invalidExpiryYear');
      } else if (fieldName === 'cvv' && value.length < 3) {
        errorMessage = t('invalidCVV');
      }
    }
    setErrors((prevErrors) => ({ ...prevErrors, [fieldName]: errorMessage }));
  };

  const handleInputChange = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    fieldName: string,
    maxLength: number,
    numericOnly: boolean
  ) => {
    const value = numericOnly ? e.target.value.replace(/\D/g, '') : e.target.value;
    const sanitizedValue = value.slice(0, maxLength);
    if (fieldName) validateField(fieldName, sanitizedValue);
    return sanitizedValue;
  };

  const handleBlur = (fieldName: string, value: string) => {
    validateField(fieldName, value);
  };

  const handlePayment = async () => {
    const formIsValid = Object.values(errors).every((error) => error === '') &&
      cardNumber && expiryMonth && expiryYear && cvv && cardholderName;

    if (!formIsValid) {
      console.error(t('formHasErrors'));
      return;
    }

    if (!sessionId) {
      console.error('Session ID not set. Please try again.');
      return;
    }


  };

  return (
    <Box
      sx={{
        display: 'flex',
      }}
    >
      <SideMenu onChangeLayoutMode={() => {}} />

      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexGrow: 1,
          minHeight: '100vh',
          backgroundColor: '#f5f5f5',
          padding: { xs: 2, sm: 4 },
          position: 'relative',
        }}
      >
        <IconButton
          onClick={() => navigate(-1)}
          sx={{
            position: 'absolute',
            top: 16,
            left: 16,
            backgroundColor: '#f5f5f5',
            color: '#4C3699',
            '&:hover': {
              backgroundColor: '#3A2771',
              color: '#fff',
            },
            width: 40,
            height: 40,
          }}
        >
          <ArrowBackIcon />
        </IconButton>

        <Grid
          container
          spacing={2}
          alignItems="center"
          justifyContent="center"
          sx={{
            maxWidth: { xs: '100%', md: '800px' },
            padding: { xs: 2, md: 0 },
          }}
        >
          <Grid
            item
            xs={12}
            md={8}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              mb: { xs: 4, md: 0 },
            }}
          >
            <Typography variant="h5" gutterBottom>
              {t('confirmPayment')}
            </Typography>

            <TextField
              label={t('cardNumber')}
              variant="outlined"
              fullWidth
              value={cardNumber}
              error={!!errors.cardNumber}
              helperText={errors.cardNumber}
              onChange={(e) =>
                setCardNumber(handleInputChange(e, 'cardNumber', 16, true))
              }
              onBlur={() => handleBlur('cardNumber', cardNumber)}
              sx={{
                mb: 2,
                borderRadius: '10px',
                '& fieldset': { borderRadius: '10px' },
              }}
            />
            <Box sx={{ display: 'flex', gap: 2 }}>
              <TextField
                label={t('expiryMonth')}
                variant="outlined"
                fullWidth
                value={expiryMonth}
                error={!!errors.expiryMonth}
                helperText={errors.expiryMonth}
                onChange={(e) =>
                  setExpiryMonth(handleInputChange(e, 'expiryMonth', 2, true))
                }
                onBlur={() => handleBlur('expiryMonth', expiryMonth)}
                sx={{
                  borderRadius: '10px',
                  '& fieldset': { borderRadius: '10px' },
                }}
              />
              <TextField
                label={t('expiryYear')}
                variant="outlined"
                fullWidth
                value={expiryYear}
                error={!!errors.expiryYear}
                helperText={errors.expiryYear}
                onChange={(e) =>
                  setExpiryYear(handleInputChange(e, 'expiryYear', 4, true))
                }
                onBlur={() => handleBlur('expiryYear', expiryYear)}
                sx={{
                  borderRadius: '10px',
                  '& fieldset': { borderRadius: '10px' },
                }}
              />
              <TextField
                label={t('cvv')}
                variant="outlined"
                fullWidth
                value={cvv}
                error={!!errors.cvv}
                helperText={errors.cvv}
                onChange={(e) => setCvv(handleInputChange(e, 'cvv', 3, true))}
                onBlur={() => handleBlur('cvv', cvv)}
                sx={{
                  borderRadius: '10px',
                  '& fieldset': { borderRadius: '10px' },
                }}
              />
            </Box>
            <TextField
              label={t('cardholderName')}
              variant="outlined"
              fullWidth
              value={cardholderName}
              error={!!errors.cardholderName}
              helperText={errors.cardholderName}
              onChange={(e) => setCardholderName(e.target.value)}
              onBlur={() => handleBlur('cardholderName', cardholderName)}
              sx={{
                mt: 2,
                mb: 2,
                borderRadius: '10px',
                '& fieldset': { borderRadius: '10px' },
              }}
            />

            <Button
              variant="contained"
              fullWidth
              sx={{
                mt: 3,
                backgroundColor: '#562173',
                color: '#fff',
                fontWeight: 'bold',
              }}
              onClick={handlePayment}
            >
              {t('confirmPayment')}
            </Button>
          </Grid>

          <Grid
            item
            xs={12}
            md={4}
            sx={{
              display: { xs: 'none', md: 'flex' },
              justifyContent: 'center',
              alignItems: 'flex-end',
              height: '100%',
            }}
          >
            <img
              src={girlPayment}
              alt={t('paymentIllustration')}
              style={{ width: '100%', maxWidth: '300px' }}
            />
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default PaymentDetailsForm;
