import React, { useState } from 'react';
import SideMenu from '../../layouts/Default/SideMenu';
import {
  Box,
  ButtonBase,
  Typography,
  Grid,
  IconButton,
} from '@mui/material';
import LockIcon from '@mui/icons-material/Lock';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import paymentImage from "../../assets/images/payment1.svg";
import visa from "../../assets/images/visa.png";
import mastercard from "../../assets/images/mastercard.png";
import elo from "../../assets/images/elo.png";
import hiper from "../../assets/images/hiper.png";
import waveImage from "../../assets/images/wave2.svg";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const PaymentMethodSelection = () => {
  const { t } = useTranslation();
  const [selectedCard, setSelectedCard] = useState<string | null>(null);
  const navigate = useNavigate();

  const handleCardSelection = (card: string) => {
    setSelectedCard(card);
    navigate('/payment-form');
  };

  return (
    <Box
      sx={{
        display: 'flex',
      }}
    >
      {/* Side Menu */}
      <SideMenu
        onChangeLayoutMode={() => {}}
      />

      {/*  Principal */}
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          flexGrow: 1,
          height: '100vh',
          backgroundColor: '#4C3699',
          backgroundImage: `url(${waveImage})`,
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          backgroundPosition: 'center bottom',
          padding: 3,
          position: 'relative',
        }}
      >
        {/* Back Button */}
        <IconButton
          onClick={() => navigate(-1)}
          sx={{
            position: 'absolute',
            top: 16,
            left: 16,
            backgroundColor: '#4C3699',
            color: '#fff',
            '&:hover': {
              backgroundColor: '#3A2771',
            },
            width: 40,
            height: 40,
          }}
        >
          <ArrowBackIcon />
        </IconButton>

        <Grid container spacing={2} alignItems="center" justifyContent="center" sx={{ maxWidth: { xs: '100%', sm: 900 } }}>
          {/* Image Column */}
          <Grid item xs={12} sm={4} md={3} sx={{ display: 'flex', justifyContent: 'center', marginTop: { xs: '-30px', sm: '-50px' } }}>
            <img src={paymentImage} alt="Pagamento Seguro" style={{ width: '80%', maxWidth: 180 }} />
          </Grid>

          {/* Text Column  */}
          <Grid item xs={12} sm={8} md={9}>
            <Typography variant="h5" sx={{ color: '#92EF8F', mb: 1, fontSize: { xs: '1.2rem', sm: '1.75rem' } }}>
              {t('paymentMethodTitle')}
            </Typography>

            <Box
              sx={{
                width: '100%',
                height: '2px',
                backgroundColor: '#92EF8F',
                mb: 1,
              }}
            />

            <Typography variant="body1" sx={{ color: '#d1d1d1', mb: 2, fontSize: { xs: '0.875rem', sm: '1rem' } }}>
              {t('paymentSubtitle')}
            </Typography>
          </Grid>
        </Grid>

        {/* Padlock and encryption text */}
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: { xs: 'flex-start', sm: 'flex-end', lg: 'flex-end' },
            mt: { xs: 2, sm: 3 },
            width: '100%',
            maxWidth: 700,
            marginLeft: { xs: 0, sm: 'auto', md: '13%' },
          }}
        >
          <LockIcon sx={{ fontSize: 16, color: '#92EF8F', mr: 1 }} />
          <Typography variant="caption" sx={{ color: '#92EF8F', fontSize: { xs: '0.75rem', sm: '0.875rem' } }}>
            {t('securePayment')}
          </Typography>
        </Box>

        {/* Payment selection section */}
        <Box
          sx={{
            backgroundColor: '#F5F5F7',
            borderRadius: 2,
            padding: 2,
            width: '95%',
            maxWidth: 700,
            mt: 2,
            marginLeft: { xs: 0, sm: 'auto', md: '14%' },
          }}
        >
          <Typography variant="subtitle1" sx={{ fontWeight: 'bold', color: 'black', mb: 2, fontSize: { xs: '1rem', sm: '1.125rem' } }}>
            {t('creditOrDebit')}
          </Typography>

          <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
            <Box sx={{ display: 'flex', flexGrow: 1 }}>
              <ButtonBase onClick={() => handleCardSelection('Visa')}>
                <img src={visa} alt="Visa" style={{ width: 40, marginRight: "10px" }} />
              </ButtonBase>
              <ButtonBase onClick={() => handleCardSelection('MasterCard')}>
                <img src={mastercard} alt="MasterCard" style={{ width: 40, marginRight: "10px" }} />
              </ButtonBase>
              <ButtonBase onClick={() => handleCardSelection('Elo')}>
                <img src={elo} alt="Elo" style={{ width: 45, marginRight: "10px" }} />
              </ButtonBase>
              <ButtonBase onClick={() => handleCardSelection('Hipercard')}>
                <img src={hiper} alt="Hipercard" style={{ width: 45, marginRight: "10px" }} />
              </ButtonBase>
            </Box>

            <IconButton
              onClick={() => navigate('/payment-form')}
              sx={{
                backgroundColor: '#F5F5F7',
                color: '#4C3699',
                ml: 2,


              }}
            >
              <ArrowForwardIosIcon />
            </IconButton>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default PaymentMethodSelection;
