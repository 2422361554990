import React from 'react';
import SideMenu from '../../layouts/Default/SideMenu';
import { Box, Typography, IconButton } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import waveImage from '../../assets/images/wave2.svg';
import cancelImage from '../../assets/images/cancel2.svg';

const CancellationSuccessScreen = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <Box
      sx={{
        display: 'flex',
      }}
    >
      {/* Side Menu */}
      <SideMenu
        onChangeLayoutMode={() => {}}
      />

      {/*  Principal */}
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          flexGrow: 1,
          minHeight: '100vh',
          backgroundColor: '#4C3699',
          backgroundImage: `url(${waveImage})`,
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          backgroundPosition: {
            xs: 'center 120%',
            sm: 'center 130%',
            md: 'center 140%',
          },
          padding: { xs: '0.5em', sm: '0.75em', md: '1.25em' },
          textAlign: 'center',
          position: 'relative',
        }}
      >
        {/* Back button */}
        <IconButton
          onClick={() => navigate(-1)}
          sx={{
            position: 'absolute',
            top: 16,
            left: 16,
            backgroundColor: '#4C3699',
            color: '#fff',
            '&:hover': {
              backgroundColor: '#3A2771',
            },
            width: 40,
            height: 40,
          }}
        >
          <ArrowBackIcon />
        </IconButton>

        <Box
          component="img"
          src={cancelImage}
          alt={t('cancelConfirmationIllustration')}
          sx={{
            maxWidth: { xs: '11.25em', sm: '13.75em', md: '18.75em' },
            height: 'auto',
            mb: '1em',
          }}
        />

        <Typography variant="h5" sx={{ fontWeight: 'bold', color: '#fff', mb: '0.5em' }}>
          {t('cancellationSuccess')}
        </Typography>
        <Typography variant="body1" sx={{ color: '#D1C4E9', maxWidth: '25em' }}>
          {t('pauseAndReturn')}
        </Typography>
      </Box>
    </Box>
  );
};

export default CancellationSuccessScreen;
