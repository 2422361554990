import React from 'react';
import SideMenu from '../../layouts/Default/SideMenu';
import {
  Box,
  Typography,
  Paper,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  Divider,
  Grid,
  IconButton,
} from '@mui/material';
import PaymentIcon from '@mui/icons-material/Payment';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import waveImage from "../../assets/images/wave2.svg";
import boyCardImage from "../../assets/images/boy-card.svg";

const PaymentScreen = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const handleManagePaymentClick = () => {
    navigate('/payment');
  };

  return (
    <Box
      sx={{
        display: 'flex',
      }}
    >
      {/* Side Menu */}
      <SideMenu
        onChangeLayoutMode={() => {}}
      />

      {/* Conteúdo Principal */}
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexGrow: 1,
          minHeight: '100vh',
          backgroundColor: '#4C3699',
          backgroundImage: `url(${waveImage})`,
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          backgroundPosition: 'center bottom',
          padding: { xs: '0.5em', sm: '0.75em', md: '1.25em' },
          position: 'relative',
        }}
      >
        {/* Back button */}
        <IconButton
          onClick={() => navigate(-1)}
          sx={{
            position: 'absolute',
            top: 16,
            left: 16,
            backgroundColor: '#4C3699',
            color: '#fff',
            '&:hover': {
              backgroundColor: '#3A2771',
            },
            width: 40,
            height: 40,
          }}
        >
          <ArrowBackIcon />
        </IconButton>

        <Paper
          sx={{
            width: '100%',
            maxWidth: { xs: '20em', sm: '31.25em', md: '38em', lg: '44em' },
            padding: { xs: '0.75em', sm: '1em', md: '1.50em' },
            borderRadius: '1em',
            backgroundColor: '#fff',
            color: '#fff',
            boxShadow: '0em 0.75em 1.5em rgba(0, 0, 0, 0.3)',
            position: 'relative',
            zIndex: 2,
            textAlign: 'center',
            marginTop: { xs: '1em', sm: '-2em', md: '-4em' },
            marginLeft: { md: '30%', lg: '12%' },
          }}
          elevation={4}
        >
          <Grid container spacing={2} alignItems="center" justifyContent="center">
            <Grid item>
              <PaymentIcon sx={{ fontSize: { xs: '1.875em', md: '2.5em' }, color: '#4C3699' }} />
            </Grid>
            <Grid item>
              <Typography variant="h5" sx={{ fontWeight: 'bold', color: '#4C3699', fontSize: { xs: '1.2rem', md: '1.5rem' } }}>
                {t('payments')}
              </Typography>
            </Grid>
          </Grid>

          <Typography variant="body2" sx={{ color: '#b3b3b3', mt: '0.5em', fontSize: { xs: '0.875rem', md: '1rem' }, textAlign: 'center' }}>
            {t('paymentDescription')}
          </Typography>

          <Divider sx={{ backgroundColor: '#92EF8F', height: '0.125em', my: '1em' }} />

          {/* Payment Options */}
          <List sx={{ backgroundColor: '#fff', borderRadius: '1em', color: '#3A2771', boxShadow: '0em 0.25em 0.5em rgba(0, 0, 0, 0.1)' }}>
            <ListItem button onClick={handleManagePaymentClick}>
              <ListItemText primary={t('managePayment')} />
              <ListItemSecondaryAction>
                <ArrowForwardIosIcon sx={{ color: '#3A2771', fontSize: '1.125em' }} />
              </ListItemSecondaryAction>
            </ListItem>
            <Divider variant="fullWidth" component="li" sx={{ marginLeft: '0.5em', marginRight: '0.5em' }} />

            <ListItem button>
              <ListItemText primary={t('paymentHistory')} />
              <ListItemSecondaryAction>
                <ArrowForwardIosIcon sx={{ color: '#3A2771', fontSize: '1.125em' }} />
              </ListItemSecondaryAction>
            </ListItem>
          </List>
        </Paper>

        {/* Boy-card image, visible on md and larger screens */}
        <Box
          component="img"
          src={boyCardImage}
          alt={t('boyHoldingCard')}
          sx={{
            display: { xs: 'none', md: 'block' },
            position: 'absolute',
            left: { md: '15%', lg: '10%' },
            bottom: { md: '30%', lg: '28%' },
            maxWidth: { md: '18.75em', lg: '28.125em' },
            height: 'auto',
            zIndex: 1,
            '@media (min-width: 1024px) and (max-width: 1024px) and (min-height: 1366px) and (max-height: 1366px)': {
              display: 'none',
            },
          }}
        />
      </Box>
    </Box>
  );
};

export default PaymentScreen;
