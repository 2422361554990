import React, { useState } from 'react';
import SideMenu from '../../layouts/Default/SideMenu';
import {
  Box,
  Typography,
  Paper,
  Radio,
  RadioGroup,
  FormControlLabel,
  Button,
  Grid,
  TextField,
  IconButton,
} from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import waveImage from '../../assets/images/wave2.svg';
import cancelImage from '../../assets/images/cancel.svg';

const CancelSubscriptionScreen = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [selectedOption, setSelectedOption] = useState('notSure');
  const [feedback, setFeedback] = useState('');

  const handleConfirmClick = () => {
    if (selectedOption === 'yes') {
      navigate('/cancel-confirmation');
    } else if (selectedOption === 'notSure') {
      navigate('/subscription');
    }
  };

  const handleOptionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedOption(event.target.value);
  };

  return (
    <Box
      sx={{
        display: 'flex',
      }}
    >
      {/* Side Menu */}
      <SideMenu
        onChangeLayoutMode={() => {}}
      />

      {/*  Principal */}
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexGrow: 1,
          minHeight: '100vh',
          backgroundColor: '#4C3699',
          backgroundImage: `url(${waveImage})`,
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          backgroundPosition: {
            xs: 'center 120%',
            sm: 'center 130%',
            md: 'center 140%',
          },
          padding: { xs: '0.5em', sm: '0.75em', md: '1.25em' },
          position: 'relative',
        }}
      >
        {/* Back button */}
        <IconButton
          onClick={() => navigate(-1)}
          sx={{
            position: 'absolute',
            top: 16,
            left: 16,
            backgroundColor: '#4C3699',
            color: '#fff',
            '&:hover': {
              backgroundColor: '#3A2771',
            },
            width: 40,
            height: 40,
          }}
        >
          <ArrowBackIcon />
        </IconButton>

        <Box
          component="img"
          src={cancelImage}
          alt={t('cancelIllustration')}
          sx={{
            display: { xs: 'none', md: 'block' },
            position: 'absolute',
            left: { md: '12%', lg: '15%' },
            bottom: { md: '15%', lg: '13%' },
            maxWidth: { md: '16.25em', lg: '21.875em' },
            height: 'auto',
            zIndex: 1,
          }}
        />

        <Paper
          sx={{
            width: '100%',
            maxWidth: '31.25em',
            padding: { xs: '0.75em', sm: '1em', md: '1.25em' },
            borderRadius: '1em',
            backgroundColor: '#ffffff',
            boxShadow: '0em 0.75em 1.5em rgba(0, 0, 0, 0.2)',
            zIndex: 2,
          }}
          elevation={4}
        >
          <Grid container alignItems="center" spacing={1}>
            <Grid item>
              <Box
                sx={{
                  width: '2.5em',
                  height: '2.5em',
                  backgroundColor: '#4C3699',
                  borderRadius: '0.5em',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <CancelIcon sx={{ color: '#fff', fontSize: '1.5em' }} />
              </Box>
            </Grid>
            <Grid item>
              <Typography variant="h5" sx={{ fontWeight: 'bold', color: '#4C3699' }}>
                {t('cancellation')}
              </Typography>
            </Grid>
          </Grid>

          <Typography variant="body1" sx={{ color: '#4C3699', mt: '1em' }}>
            {t('cancelConfirmation')}
          </Typography>

          <RadioGroup
            value={selectedOption}
            onChange={handleOptionChange}
            sx={{ mt: '0.5em' }}
          >
            <FormControlLabel
              value="yes"
              control={<Radio />}
              label={t('yes')}
              sx={{
                backgroundColor: '#F7F7F7',
                borderRadius: '1em',
                padding: '0.5em',
                mb: '0.25em',
                width: '100%',
                cursor: 'pointer',
                color: selectedOption === 'yes' ? '#4C3699' : 'inherit',
                '& .MuiRadio-root': {
                  color: '#4C3699',
                  '&.Mui-checked': { color: '#4C3699' },
                },
              }}
            />
            <FormControlLabel
              value="notSure"
              control={<Radio />}
              label={t('notSure')}
              sx={{
                backgroundColor: '#F7F7F7',
                borderRadius: '1em',
                padding: '0.5em',
                width: '100%',
                cursor: 'pointer',
                color: selectedOption === 'notSure' ? '#4C3699' : 'inherit',
                '& .MuiRadio-root': {
                  color: '#4C3699',
                  '&.Mui-checked': { color: '#4C3699' },
                },
              }}
            />
          </RadioGroup>

          {selectedOption === 'yes' && (
            <Box sx={{ mt: '1em' }}>
              <Typography variant="body2" sx={{ color: '#4C3699', mb: '0.25em' }}>
                {t('feedbackRequest')}
              </Typography>
              <TextField
                placeholder={t('typeHere')}
                variant="outlined"
                fullWidth
                multiline
                minRows={3}
                value={feedback}
                onChange={(e) => setFeedback(e.target.value)}
                sx={{
                  backgroundColor: '#F7F7F7',
                  borderRadius: '1em',
                  '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                      borderColor: 'transparent',
                    },
                    '&:hover fieldset': {
                      borderColor: '#4C3699',
                    },
                    '&.Mui-focused fieldset': {
                      borderColor: '#4C3699',
                    },
                  },
                }}
              />
            </Box>
          )}

          <Button
            variant="contained"
            onClick={handleConfirmClick}
            sx={{
              backgroundColor: '#4C3699',
              color: '#fff',
              mt: '1em',
              paddingX: '1.5em',
              '&:hover': {
                backgroundColor: '#3A2771',
              },
            }}
          >
            {t('confirm')}
          </Button>
        </Paper>
      </Box>
    </Box>
  );
};

export default CancelSubscriptionScreen;
