import React, { useEffect, useState } from "react";
import { Alert, Row, Col } from "reactstrap";
import { Navigate, useLocation, useNavigate, Link } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { useProfile, useRedux } from "../../hooks";
import { createSelector } from "reselect";
import { loginUser } from "../../redux/actions";
import NonAuthLayoutWrapper from "../../components/NonAuthLayoutWrapper";
import Loader from "../../components/Loader";
import { CookieConsent } from "../../components/CookieConsent";
import "react-toastify/dist/ReactToastify.css";
import { useTranslation } from "react-i18next";

import {
  Button,
  TextField,
  IconButton,
  InputAdornment,
  Typography,
} from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";

interface LoginProps {}

const Login = (props: LoginProps) => {
  const { dispatch, useAppSelector } = useRedux();
  const { t } = useTranslation();

  const errorData = createSelector(
    (state: any) => state.Login,
    state => ({
      isUserLogin: state.isUserLogin,
      error: state.error,
      loginLoading: state.loading,
      isUserLogout: state.isUserLogout,
    }),
  );

  const { isUserLogin, error, loginLoading, isUserLogout } =
    useAppSelector(errorData);
  const { userProfile, loading } = useProfile();

  const navigate = useNavigate();
  const location = useLocation();
  const [redirectUrl, setRedirectUrl] = useState("/");

  useEffect(() => {
    const url = location.state?.from?.pathname || "/";
    setRedirectUrl(url);
  }, [location]);

  useEffect(() => {
    if (isUserLogin && !loginLoading && !isUserLogout) {
      navigate(redirectUrl);
    }
  }, [isUserLogin, navigate, loginLoading, isUserLogout, redirectUrl]);

  const resolver = yupResolver(
    yup.object().shape({
      email: yup
        .string()
        .email(t("emailInvalid"))
        .min(5, t("emailTooShort"))
        .max(254, t("emailTooLong"))
        .required(t("emailRequired")),
      password: yup.string().required(t("passwordRequired")),
    }),
  );

  const methods = useForm({ resolver });
  const {
    handleSubmit,
    register,
    formState: { errors },
    setValue,
  } = methods;

  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleEmailBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    // Normalize email input by trimming spaces and converting to lowercase
    const normalizedValue = e.target.value.trim().toLowerCase();
    setValue("email", normalizedValue, { shouldValidate: true });
  };

  const onSubmitForm = async (values: any) => {
    const processedValues = {
      ...values,
      email: values.email.trim().toLowerCase(),
    };

    console.log(processedValues);
    dispatch(loginUser(processedValues));
  };

  if (userProfile && !loading) {
    return <Navigate to={{ pathname: redirectUrl }} />;
  }

  return (
    <NonAuthLayoutWrapper>
      <CookieConsent />
      <Row className="justify-content-center my-auto">
        <Col sm={8} lg={6} xl={5} className="col-xxl-4 w-75 w-md-100">
          <div className="py-md-5 py-4">
            <div>
              {error && <Alert color="danger">{t(error)}</Alert>}
              <form
                onSubmit={handleSubmit(onSubmitForm)}
                className="position-relative"
              >
                {loginLoading && <Loader />}

                <div className="mb-3">
                  <TextField
                    label={t("email")}
                    type="email"
                    fullWidth
                    {...register("email")}
                    error={!!errors.email}
                    helperText={errors.email ? errors.email.message : ""}
                    variant="outlined"
                    InputProps={{
                      style: {
                        borderRadius: "8px",
                        backgroundColor: "#fff",
                      },
                    }}
                    onBlur={handleEmailBlur}
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        "&:hover fieldset": {
                          borderColor: "#4e3699",
                        },
                        "&.Mui-focused fieldset": {
                          borderColor: "#4e3699",
                        },
                      },
                    }}
                  />
                </div>

                <div className="mb-3">
                  <TextField
                    label={t("password")}
                    type={showPassword ? "text" : "password"}
                    fullWidth
                    {...register("password")}
                    error={!!errors.password}
                    helperText={errors.password ? errors.password.message : ""}
                    variant="outlined"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            onClick={handleClickShowPassword}
                            edge="end"
                          >
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      ),
                      style: {
                        borderRadius: "8px",
                        backgroundColor: "#fff",
                      },
                    }}
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        "&:hover fieldset": {
                          borderColor: "#4e3699",
                        },
                        "&.Mui-focused fieldset": {
                          borderColor: "#4e3699",
                        },
                      },
                    }}
                  />
                </div>

                <div className="text-end mb-3">
                  <Typography
                    component={Link}
                    to="/auth-recoverpw"
                    variant="body2"
                    sx={{
                      color: "#6c757d",
                      textDecoration: "none",
                      "&:hover": {
                        color: "#4e3699",
                        fontWeight: "bold",
                      },
                    }}
                  >
                    {t("Forgot your password?")}
                  </Typography>
                </div>

                <div className="text-center mt-4">
                  <Button
                    color="primary"
                    variant="contained"
                    fullWidth
                    type="submit"
                    sx={{
                      backgroundColor: "#4e3699",
                      borderRadius: "8px",
                      fontWeight: "bold",
                      "&:hover": {
                        backgroundColor: "#3d2c7d",
                      },
                    }}
                  >
                    {t("loginButton")}
                  </Button>
                </div>
              </form>
            </div>
          </div>
        </Col>
      </Row>
    </NonAuthLayoutWrapper>
  );
};

export default Login;
